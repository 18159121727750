<template>
  <div class="hors-dashboard waiting-verif">
    <!-- logo -->
    <div class="img-large">
      <picture>
        <source srcset="https://storage.googleapis.com/detectiveb_assets/woodlock/img/logo-monogramme.webp"
          type="image/webp" />
        <img class="logo-icon" src="https://storage.googleapis.com/detectiveb_assets/woodlock/img/logo-monogramme.png"
          alt />
      </picture>
      <picture>
        <source srcset="https://storage.googleapis.com/detectiveb_assets/woodlock/img/logo-texte.webp"
          type="image/webp" />
        <img class="logo-text" src="https://storage.googleapis.com/detectiveb_assets/woodlock/img/logo-texte.png" alt />
      </picture>
    </div>
    <p class="text">
      Veuillez valider votre adresse mail grâce au mail de vérification qui vous
      a été envoyé.
      <br />Celui-ci se trouve peut-être dans vos spams.
    </p>
    <div>
      <a class="button button-link" href @click.prevent="this.handleResendMail">
        <span>Renvoyer un e-mail de vérification</span>
      </a>
      <a class="button button-secondary button-link" href @click="this.disconnect">
        <span>RETOUR</span>
      </a>
    </div>

    <div class="overlay"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
export default {
  methods: {
    ...mapActions(["resendMail", "disconnect"]),
    handleResendMail() {
      this.resendMail().then(() => {
        this.disconnect(false)
        this.$router.push({ name: "Login" })
      })
    }
  },
}
</script>

<style lang="scss">
.mobile .waiting-verif {
  .text {
    margin: 5vh 4rem;
    font-size: 3rem;
    font-weight: 400;
    line-height: 4.5rem;
  }

  .button {
    span {
      display: inline-block;
      font-size: 2.5rem;
      margin: 0 auto;
    }
  }
}
</style>
