<template>
  <div :class="this.class">
    <div class="notification-popup">
      <p v-if="this.buttonType === 'reinforcment'">
        {{ this?.data.index + 1 + "/" + this?.data.texts.length }}
      </p>
      <div class="notification-container">
        <p>{{ notification }}</p>
        <div v-if="this?.data.image" class="notification-image">
          <picture>
            <source
              :srcset="'https://storage.googleapis.com/detectiveb_assets/woodlock/img/' + this?.data.image.img + '.webp'"
              type="image/webp" />
            <img :src="'https://storage.googleapis.com/detectiveb_assets/woodlock/img/' + this?.data.image.img + '.png'"
              alt />
          </picture>
        </div>
        <button v-if="this.buttonType === 'close'" class="button" @click="this.removeNotif">
          FERMER
        </button>
        <button v-else-if="this.buttonType === 'document'" class="button" @click="this.documentButton()">
          Consulter {{ this.documentTypeText() }}
        </button>
        <div v-else-if="this.buttonType === 'reinforcment'" class="arrow-buttons">
          <button v-if="this?.data.index > 0" class="button" @click="this.reinforcmentButtonPrev()">
            <vue-feather tag="div" type="chevron-left" />
            <span>Précédent</span>
          </button>
          <button v-if="this?.data.index < this?.data.texts.length - 1" class="button"
            @click="this.reinforcmentButtonNext()">
            <span>Suivant</span>
            <vue-feather tag="div" type="chevron-right" />
          </button>
        </div>
        <div class="arrow-buttons" v-else-if="this.buttonType === 'delete'">
          <button class="button" @click="this.removeNotif">ANNULER</button>
          <button class="button button-delete" @click="this.delete">SUPPRIMER</button>
        </div>
      </div>
      <vue-feather tag="button" type="x" class="button button-close" @click="this.removeNotif" />
    </div>
    <div class="overlay" @click="this.removeNotif" @keydown="this.removeNotif" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  methods: {
    ...mapActions([
      "removeNotif",
      "setReinforcmentNotif",
      "deleteDetective",
      "setDocument",
    ]),
    documentTypeText() {
      switch (this?.data.type) {
        case "investigation":
          return "l'interrogatoire";
        case "archive":
          return "le dossier";
        case "search":
          return "la fouille";
        default:
          return "le document";
      }
    },
    documentButton() {
      this.setDocument({
        type: this?.data.type,
        documentId: this?.data.documentId,
        box: this?.data.box,
        name: this?.data.name,
        new: true,
      });
      this.$router.push({
        name: "Document",
      });
      this.removeNotif();
    },
    reinforcmentButtonPrev() {
      const prevIndex = this?.data.index - 1;
      if (prevIndex < 0) {
        prevIndex = 0;
      }
      this.setReinforcmentNotif({
        notif: this?.data.texts[prevIndex],
        index: prevIndex,
        texts: this?.data.texts,
        image: this.getImage(this?.data, prevIndex),
        images: this?.data?.images,
      });
    },
    reinforcmentButtonNext() {
      const nextIndex = this?.data.index + 1;
      if (nextIndex >= this?.data.texts.length) {
        nextIndex = this?.data.texts.length - 1;
      }
      this.setReinforcmentNotif({
        notif: this?.data.texts[nextIndex],
        index: nextIndex,
        texts: this?.data.texts,
        image: this.getImage(this?.data, nextIndex),
        images: this?.data?.images,
      });
    },
    async delete() {
      await this.deleteDetective();
      this.$router.push({ name: "Login" });
    },
    getImage: (help, index) => {
      if (help?.images) {
        for (const image of help.images) {
          if (image.id === index) {
            return image;
          }
        }
      }
      return null;
    },
  },
  computed: {
    ...mapState({
      notification: (state) => state.notif.notification,
      buttonType: (state) => state.notif.buttonType,
      data: (state) => state.notif.data,
    }),
    class() {
      return this.notification === null ? "notification empty" : "notification";
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixins.scss";

.notification {
  &.empty {
    display: none;
  }

  .notification-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: rgba($black, 0.9);
    overflow-wrap: break-word;
    padding: 2em 2em 1em 2em;
    max-width: 40dvw;
    width: max-content;
    border: 1px solid $red;

    @include mobile {
      max-width: 90dvw;
      padding: 1em;
      padding-top: 2em;

      p {
        font-size: 2.5rem;
        line-height: 3rem;
      }
    }

    p {
      white-space: break-spaces;
      margin: 1rem;
    }

    .notification-container {
      max-height: calc(85dvh - 4rem);
      height: 100%;
      width: 100%;
      overflow-y: auto;

      p {
        white-space: break-spaces;
        margin: 1rem;
      }
    }

    .notification-image {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1rem;

      picture {
        * {
          max-height: calc(60dvh - 3rem);
          object-fit: contain;
          max-width: 35dvw;

          @include mobile {
            max-width: 84dvw;
          }
        }
      }
    }

    .img {
      max-width: 80%;
    }

    .button-close {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      margin: 0;
      width: 1.25em;
      height: 1.25em;
      padding: 0.33em;
      border-radius: 100%;

      @include mobile {
        height: 3rem;
        width: 3rem;
      }

      svg {
        height: 100%;
        width: 100%;
      }
    }

    button {
      font-size: 0.75em;
      margin: 0 auto;

      @include mobile {
        font-size: 1.75rem;
        width: fit-content;
        justify-content: center;
      }

      span {
        height: 24px;
        line-height: 20px;
      }

      &.button-delete {
        border: solid 1px $red;
        background-color: $black;
      }
    }

    .arrow-buttons {
      display: flex;
      justify-content: center;

      @include mobile {
        button {
          width: 45%;
          padding: 1rem;
        }
      }

      .button {
        margin-inline: 1rem;
      }

      /*
      button:first-of-type {
        margin-right: 1rem;
        margin-left: auto;
      }
      button:last-of-type {
        margin-left: 1rem;
        margin-right: auto;
      }
      */
    }
  }

  .overlay {
    z-index: 1;
    background-color: rgba($grey, 0.75);
  }
}
</style>
