<template>
  <div class="login hors-dashboard">
    <div class="img-large">
      <picture>
        <source srcset="https://storage.googleapis.com/detectiveb_assets/woodlock/img/logo-monogramme.webp"
          type="image/webp" />
        <img class="logo-icon" src="https://storage.googleapis.com/detectiveb_assets/woodlock/img/logo-monogramme.png"
          alt />
      </picture>
      <picture>
        <source srcset="https://storage.googleapis.com/detectiveb_assets/woodlock/img/logo-texte.webp"
          type="image/webp" />
        <img class="logo-text" src="https://storage.googleapis.com/detectiveb_assets/woodlock/img/logo-texte.png" alt />
      </picture>
    </div>

    <form @submit.prevent="formHandler">
      <h1>IDENTIFIEZ-VOUS</h1>
      <div class="input-container">
        <div class="fa">
          <vue-feather tag="div" type="mail" />
        </div>
        <label for="mail">Adresse e-mail</label>
        <input type="email" name="mail" v-model="mail" placeholder="Adresse e-mail" />
      </div>
      <div class="input-container">
        <div class="fa">
          <vue-feather tag="div" type="lock" />
        </div>
        <label for="password">Mot de passe</label>
        <input type="password" name="password" v-model="password" placeholder="Mot de passe" />
      </div>
      <router-link class="link" :to="{ name: 'ResetRequest' }">
        <span>Mot de passe perdu</span>
      </router-link>
      <input class="button" type="submit" value="DÉVERROUILLER" />
    </form>
    <div>
      <p>Première connexion ?</p>
      <router-link class="button button-link" :to="{ name: 'Signup' }">
        <span>SE CREER UN COMPTE</span>
      </router-link>
    </div>
    <div class="overlay"></div>
    <LegalLinks />
  </div>
</template>

<script>
import LegalLinks from "@/components/LegalLinks.vue"
import { mapActions } from "vuex"
export default {
  components: {
    LegalLinks,
  },
  data() {
    return {
      mail: "",
      password: "",
    }
  },
  methods: {
    // récupère les actions login et fetchDetective du store
    ...mapActions(["login", "fetchDetective", "setNotif"]),
    // définit la fonction qui est éxécuté en cas d'envoi du formulaire
    formHandler() {
      if (this.password && this.mail) {
        this.login({ password: this.password, mail: this.mail.trim() })
          .then(() =>
            this.fetchDetective().then(() =>
              this.$router.push({ name: "BoxChoice" })
            )
          )
      } else {
        this.setNotif("Veuillez remplir tout les champs.")
      }

    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixins.scss";

.link {
  background-color: transparent;
  border: none;
  margin-bottom: 1rem;
  text-decoration: none;
  color: $white;

  &:hover {
    color: lighten($red, 10%);
  }
}
</style>
