<template>
  <div class="signup hors-dashboard">
    <!-- logo -->
    <div class="img-large">
      <picture>
        <source srcset="https://storage.googleapis.com/detectiveb_assets/woodlock/img/logo-monogramme.webp"
          type="image/webp" />
        <img class="logo-icon" src="https://storage.googleapis.com/detectiveb_assets/woodlock/img/logo-monogramme.png"
          alt />
      </picture>
      <picture>
        <source srcset="https://storage.googleapis.com/detectiveb_assets/woodlock/img/logo-texte.webp"
          type="image/webp" />
        <img class="logo-text" src="https://storage.googleapis.com/detectiveb_assets/woodlock/img/logo-texte.png" alt />
      </picture>
    </div>
    <!-- formulaire d'inscription -->
    <form @submit.prevent="formHandler">
      <h1>CRÉEZ VOTRE PROFIL</h1>
      <div class="text">
        <p>Il n'est pas obligatoire de reprendre l'adresse mail utilisée pour la commande</p>
      </div>
      <div class="input-container">
        <div class="fa">
          <vue-feather tag="div" type="user" />
        </div>
        <label for="name">Nom</label>
        <input type="text" name="name" v-model="name" placeholder="Nom" />
      </div>
      <div class="input-container">
        <div class="fa">
          <vue-feather tag="div" type="mail" />
        </div>
        <label for="mail">Adresse e-mail</label>
        <input type="email" name="mail" v-model="mail" placeholder="Adresse e-mail" />
      </div>
      <vue-tel-input v-bind="vuetel.props" style="z-index: 10; color: #000" v-model="phone"></vue-tel-input>
      <div class="input-container">
        <div class="fa">
          <vue-feather tag="div" type="users" />
        </div>
        <label for="nbPlayer">Nombre de joueurs</label>
        <input type="number" name="nbPlayer" v-model="nbPlayer" placeholder="1" />
      </div>
      <div class="input-container">
        <div class="fa">
          <vue-feather tag="div" type="calendar" />
        </div>
        <label for="ageMean">Moyenne d'age</label>
        <select name="ageMean" v-model="ageMean">
          <option value disabled selected>Sélectionnez une tranche d'âge</option>
          <option value="14-18">14-18</option>
          <option value="19-25">19-25</option>
          <option value="26-35">26-35</option>
          <option value="36-45">36-45</option>
          <option value="46+">46+</option>
        </select>
      </div>
      <div class="input-container">
        <div class="fa">
          <vue-feather tag="div" type="lock" />
        </div>
        <label for="password">Mot de passe</label>
        <input type="password" name="password" v-model="password" placeholder="Mot de passe" />
      </div>
      <input class="button" type="submit" value="VALIDER" />
    </form>
    <router-link class="button button-secondary button-link" :to="{ name: 'Login' }">
      <span>J'AI DÉJÀ UN COMPTE</span>
    </router-link>
    <div class="overlay"></div>
    <LegalLinks />
  </div>
</template>

<script>
import LegalLinks from "@/components/LegalLinks.vue"
import { mapActions } from "vuex"
import { VueTelInput } from 'vue-tel-input';

export default {
  components: {
    LegalLinks,
    VueTelInput
  },
  data() {
    return {
      name: "",
      mail: "",
      phone: "",
      password: "",
      nbPlayer: "",
      ageMean: "",
      vuetel: {
        props: {
          "class": "input-container",
          "inputOptions": { placeholder: "Téléphone" },
          defaultCountry: "fr",
          mode: "international",
        }
      }
    }
  },
  methods: {
    // récupère les actions login et fetchDetective du store
    ...mapActions(["signup", "fetchDetective", "setNotif"]),
    // fonction qui est éxécuté en cas d'envoi du formulaire
    formHandler() {
      // créé l'objet détective à partir des champs de formulaires
      let phone = this.phone

      const detective = {
        name: this.name.trim(),
        mail: this.mail.trim(),
        phoneNumber: phone,
        nbPlayer: this.nbPlayer || 1,
        ageMean: this.ageMean || "19-25"
      }

      if (detective.name && detective.mail && this.password) {
        // éxécute la fonction signup du store pour inscrire l'utilisateur
        this.signup({ password: this.password, detective: detective }).then(
          () => this.$router.push({ name: "Login" }) // redirige vers la page de connexion
        )
      } else {
        this.setNotif("Veuillez remplir tout les champs")
      }
    },
  },
}
</script>
